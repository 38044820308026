import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import TopDark from '../../Modules/Top/TopDarkRelative';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import Footer from '../../Modules/FooterPage';

// LOGO IMAGE 
import DanfossSert from './Serts/danfoss_sert.jpg'

const DanfossPageHtml = () => {
    return(
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <TopDark />
                    </Col>
                </Row>
            </Container>
            <div className="refeng-page-container" style={{display: 'grid', alignContent: 'center'}}>
                <div className="postcontent">
                    <Container fluid>
                        <Row>
                            <Col xl={8}>
                                <h4>Компания Danfoss</h4>
                                <br/>
                                <p>История инновационной деятельности Danfoss началась в 1933 году, когда 
                                    Мадс Клаузен основал компанию «Данфосс» (Danfoss). С тех пор компания из 
                                    небольшого предприятия превратилась в одного из мировых лидеров отрасли.</p>
                                <p>
                                    «Данфосс» насчитывает более 28 000 сотрудников, которые работают более чем в 100 странах.
                                </p>
                                <p>
                                    <strong>Сайт компании:</strong>
                                    <a href="https://www.danfoss.com/" rel="noopener noreferrer nofollow" target = "_blank"  aria-label="Danfoss website">
                                    &nbsp;&nbsp;www.danfoss.com
                                    </a>
                                </p>
                            </Col>
                            <Col></Col>
                            <Col xl={2} style={{paddingTop: '7vh'}}>
                                <Zoom>
                                    <img
                                    alt="Сертификат о партнерстве компании Рефинжиниринг с Danfoss"
                                    src={DanfossSert}
                                    width="100%"
                                    />
                                </Zoom>
                            </Col>
                            <Col></Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default DanfossPageHtml