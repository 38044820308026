import React from 'react';
import Seo from "../../components/seo"
import DanfossPageHtml from '../../components/OtherPages/PartnersPages/danfosspage';

function DanfossPage() {
    return (
        <>
            <Seo 
                title="Партнер Рефинжиниринг Danfoss"
                description="Официальный партнер компании 'Рефинжиниринг'-Danfoss"
                keywords="Danfoss, электроника, контроллеры, холодильная, партнер"
            />
            <DanfossPageHtml />
        </>
    );
}

export default DanfossPage;